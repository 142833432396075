
import { Vue, Component } from 'vue-property-decorator';
import JoinTerms from '@/views/user/join-terms.vue';

@Component({
  name: 'AppJoin',
  components: {
    JoinTerms,
  },
})
export default class extends Vue {
}
